<template>
  <div
    ref="test"
    class="personApply_container">
    <div class="personApply_container_top_bar">
      <h1 class="personApply_container_top_bar_tilte">INDUVIDUAL & SOLE TRADER & 个体经营</h1>
      <h1 class="personApply_container_top_bar_subtilte">APPLICATION FORM 申请表</h1>
    </div>
    <a-tabs
      :default-active-key="getCurrentTabIndex"
      :activeKey="getCurrentTabIndex"
      tab-position="top"
      :style="{ height: '60px',position: 'sticky', top:0,backgroundColor: 'rgb(248,248,248)', zIndex: 4}"
      size="large"
      type="line"
      :tabBarGutter="20"
      @tabClick="handleTabClick"
    >
        <a-tab-pane style="height: 0" key="1">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">1</div> APPLICANT DETAILS 个人信息</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">2</div> ACCOUNT BEHAVIOUR BASELINE 账户行为基准</div>
        </template>
      </a-tab-pane>
      <!-- <a-tab-pane key="3">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">3</div>AUTHORIZED PERSON 授权人</div>
        </template>
      </a-tab-pane> -->
      <a-tab-pane key="3">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">3</div>ACKNOWLEDEMENT 责任书</div>
        </template>
      </a-tab-pane>
      <a-tab-pane
        key="4"
      >
      <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">4</div>PEP PERSONAL DECLARATION 政治敏感人物个人声明</div>
        </template>
      </a-tab-pane>
    </a-tabs>
    <keep-alive>
    <router-view
    :key='$route.fullPath'/>
    </keep-alive>
    <div class="button_wrapper">
      <button v-if='isPreviewBtnShow' @click="handleToPrevBtn"  class="btn_lay pre_btn">PREVIOUS
      </button>
      <div v-else></div>
      <button
      :disabled='nextBtnAllow'
      v-if="isNextBtnShow" @click="handleToNextStep" class="btn_lay next_btn">NEXT
      </button>
      <button
      :disabled='submitBtnAllow'
      @click="handleSubmit"
      v-else class="btn_lay next_btn">{{disabled?'CLOSE':'SUBMIT'}}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { kycIndividualSubmit, fetchIndividual } from '@/api/common'

export default {
  data () {
    return {
      tabarIndex: 1,
      nextBtnAllow: false,
      submitBtnAllow: false
    }
  },

  created () {
    fetchIndividual().then((res) => {
      if (!Array.isArray(res)) {
        const { data } = res
        if (Object.keys(data).length > 3) {
          // retrive data from backend
          const newKey = ['employment_status', 'nz_resident',
            'nz_au_passport_issue_date', 'cn_passport_not_required_issue_date',
            'nz_au_passport_expiry_date', 'nz_au_driver_issue_date', 'nz_au_driver_expiry_date', 'cn_passport_not_required_expiry_date', 'cn_passport_issue_date', 'cn_identify_issue_date', 'cn_passport_expiry_date', 'cn_identify_expiry_date', 'cn_bank_issue_date', 'cn_bank_expiry_date', 'other_identify_issue_date', 'other_identify_expiry_date', 'birth_certificate_num', 'birth_certificate_country', 'birth_certificate_file_list', 'nz_citizenship_num', 'nz_citizenship_country', 'nz_citizenship_issue_date', 'nz_citizenship_expiry_date', 'nz_citizenship_front_file_list', 'nz_citizenship_back_file_list', 'other_national_id_num', 'other_national_id_country', 'other_national_id_issue_date', 'other_national_id_expiry_date', 'other_national_id_front_file_list', 'other_national_id_back_file_list', 'aml_announcement_check', 'is_pep_need_to_report']

          newKey.forEach((item) => {
            if (data[item] !== undefined) {
            } else {
              data[item] = null
            }
          })
          this.ReSetFirstFormAction(data)
          this.ReSetSecondFormAction(data)
          this.ReSetThirdFormAction(data)
          this.ReSetFourthFormAction(data)
          this.ReSetFifthFormAction(data)
        } else {
          this.initFirstFormAction(data)
          this.initSecondFormAction()
          this.initThirdFormAction()
          this.initFourthFormAction()
          this.initFifthFormAction()
        }
      }
    }
    ).catch(() => {
      this.$message.error('Auth Fail Please Re-login in')
      this.initFirstFormAction()
      this.initSecondFormAction()
      this.initThirdFormAction()
      this.initFourthFormAction()
      this.initFifthFormAction()
    })
  },

  computed: {
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    ...mapState('login', ['user']),
    ...mapGetters('personStepOne', [
      'GetStepOne'
    ]),
    ...mapGetters('personStepTwo', [
      'GetStepTwo'
    ]),
    ...mapGetters('personStepThree', [
      'GetStepThree'
    ]),
    ...mapGetters('personStepFour', [
      'GetStepFour'
    ]),
    ...mapGetters('personStepFive', [
      'GetStepFive'
    ]),
    isNextBtnShow () {
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] < 4) {
        return true
      }
      return false
    },
    isPreviewBtnShow () {
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] > 1) {
        return true
      }
      return false
    },
    getCurrentTabIndex () {
      const tabIndex = this.$route.path.split('/')[3]
      return tabIndex
    }
  },
  methods: {

    ...mapActions('login', ['SetUserDetailProfile']),
    ...mapActions('personStepOne', ['ReSetFirstFormAction', 'initFirstFormAction']),
    ...mapActions('personStepTwo', ['ReSetSecondFormAction', 'initSecondFormAction']),
    ...mapActions('personStepThree', ['ReSetThirdFormAction', 'initThirdFormAction']),
    ...mapActions('personStepFour', ['ReSetFourthFormAction', 'initFourthFormAction']),
    ...mapActions('personStepFive', ['ReSetFifthFormAction', 'initFifthFormAction']),
    handleToNextStep () {
      if (this.disabled) {
        this.$emit('scrollTotop')
        const nextTabIndex = +this.getCurrentTabIndex + 1
        this.$router.push('/user/personapplication/' + nextTabIndex)
        return
      }
      this.nextBtnAllow = true
      const query = {
        ...this.GetStepOne,
        ...this.GetStepTwo,
        ...this.GetStepThree,
        ...this.GetStepFour,
        ...this.GetStepFive
      }
      kycIndividualSubmit(query).then(res => {
        this.$message.success('上传成功')
      }).then(() => {
        fetchIndividual().then((res) => {
          if (!Array.isArray(res)) {
            const { data } = res
            if (Object.keys(data).length > 3) {
              this.ReSetFirstFormAction(data)
              this.ReSetSecondFormAction(data)
              this.ReSetThirdFormAction(data)
              this.ReSetFourthFormAction(data)
              this.ReSetFifthFormAction(data)
            } else {
              this.initFirstFormAction(data)
              this.initSecondFormAction()
              this.initThirdFormAction()
              this.initFourthFormAction()
              this.initFifthFormAction()
            }
          }
        })
      }
      ).then(() => {
        this.$emit('scrollTotop')
        this.nextBtnAllow = false
        const nextTabIndex = +this.getCurrentTabIndex + 1
        this.$router.push('/user/personapplication/' + nextTabIndex)
      }).catch(() => {
        this.nextBtnAllow = false
        this.$emit('scrollTotop')
        const nextTabIndex = +this.getCurrentTabIndex + 1
        this.$router.push('/user/personapplication/' + nextTabIndex)
      })
    },
    handleToPrevBtn () {
      const nextTabIndex = +this.getCurrentTabIndex - 1
      this.$router.push('/user/personapplication/' + nextTabIndex)
    },

    handleTabClick (e) {
      if (e === this.getCurrentTabIndex) {
        return false
      }
      this.$router.push('/user/personapplication/' + e)
    },
    handleSubmit () {
      if (this.disabled) {
        this.$router.push({ name: 'UserPage' })
        return
      }
      this.submitBtnAllow = true
      const query = {
        is_submit: true,
        ...this.GetStepOne,
        ...this.GetStepTwo,
        ...this.GetStepThree,
        ...this.GetStepFour,
        ...this.GetStepFive
      }
      kycIndividualSubmit(query).then(res => {
        this.$message.success('上传成功')
      }).then(() => {
        this.submitBtnAllow = false
        this.$router.push({ name: 'UserPage' })
      }).catch(() => {
        this.submitBtnAllow = false
        this.$message.error('Server error!')
      })
    }
  }

}
</script>

<style scoped>
.tabbar_slot{
  display: flex;
  align-items: center;
}
.tabbar_circle{
  width: 28px;
  margin-right: 10px;
  height: 28px;
  border-radius: 50%;
  font-weight: 500;
  border: 2px solid #ea7840;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personApply_container_top_bar_tilte{
  font-size: 24px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar_subtilte{
  font-size: 35px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container{
  min-height: 100vh;
  background: rgb(248,248 , 248);
  padding-bottom: 30px;
}
.button_wrapper{
  max-width: 700px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.personApply_container_top_bar{
  background: #373737;
  height: 170px;
  padding-top: 30px;
}
.btn_lay{
  width: 240px;
  height: 60px;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
.btn_lay:hover{
  background: #ea7840;
  color: #fff;

}
.pre_btn:disabled{
  cursor: not-allowed;
}
.next_btn{
  background: #ea7840;
  color: #fff;
  position: relative;
}
.next_btn:disabled{
  background: rgb(168,168 , 168);
  border-color: rgb(168,168 , 168);
  color: rgb(0,0,0,.65);
  cursor: not-allowed;
}
.next_btn:disabled::after {
  position: absolute;
  top: 0;
  display: flex;
  content: 'Uploading..';
  font-weight: 400;
  color: rgb(0,0,0,.65);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(168,168 , 168);
}

@media only screen and (max-width: 768px) {
.personApply_container_top_bar_tilte{
  font-size: 4.6vw;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar_subtilte{
  font-size: 6vw;
  margin-top: 2vh;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar{
  background: #373737;
  height: 20vh;
  padding-top: 30px;
}
.btn_lay{
  width: 36vw;
  height: 8vh;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 4.6vw;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
.button_wrapper{
  padding: 0 3vw;
}
.personApply_container{
  padding-bottom: 1px;
}
}
</style>
